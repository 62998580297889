import React from 'react';
import {Form} from "react-bootstrap";
import FormField from "./FormField";

const parentForm: FormField[] = [
    {
        label: "Student's Name",
        id: "parent:student_name",
        control: <Form.Control type={"text"} />,
        required: true,
    },
    {
        label: "CCPS Student ID (where applicable)",
        id: "parent:student_id",
        control: <Form.Control type={"text"} />,
        required: false,
    },
    {
        label: "Mother's Name",
        id: "parent:mother_name",
        control: <Form.Control type={"text"} />,
        required: false,
    },
    {
        label: "Father's Name",
        id: "parent:father_name",
        control: <Form.Control type={"text"} />,
        required: false,
    },
    {
        label: "Parents are",
        id: "parent:relationship_status",
        control:  <Form.Control as="select">
            <option disabled selected> -- select an option -- </option>
            <option>Married</option>
            <option>Divorced</option>
            <option>Separated</option>
            <option>Not Married</option>
        </Form.Control>,
        required: true
    },
    {
        label: "Student lives with (check all that apply):",
        id: "parent:student_lives_with",
        required: true,
        control: <Form.Control as="select" multiple>
            <option disabled selected> -- select an option -- </option>
            <option>Mother</option>
            <option>Father</option>
            <option>Stepmother</option>
            <option>Stepfather</option>
            <option>Grandmother</option>
            <option>Grandfather</option>
            <option>Guardian</option>
            <option>Other</option>
        </Form.Control>
    },
    {
        label: "If you selected other, please specify:",
        id: "parent:student_lives_with_other",
        required: false,
        control: <Form.Control type={"text"} />,
    },
    {
        label: "Mailing Address",
        id: "parent:address",
        required: true,
        control: <Form.Control type={"text"} />
    },
    {
        label: "Zip",
        id: "parent:zip",
        required: true,
        control: <Form.Control type={"text"} />
    },
    {
        label: "Home Phone",
        id: "parent:home_phone",
        required: false,
        control: <Form.Control type={"phone"} />
    },
    {
        label: "Parent/Guardian #1 Cell",
        id: "parent:guardian_1_cell",
        required: false,
        control: <Form.Control type={"phone"} />
    },
    {
        label: "What's the relationship of this parent/guardian?",
        id: "parent:guardian_1",
        required: false,
        control: <Form.Control type={"text"} />
    },
    {
        label: "Parent/Guardian #2 Cell",
        id: "parent:guardian_2_cell",
        required: false,
        control: <Form.Control type={"phone"} />
    },
    {
        label: "What's the relationship of this parent/guardian?",
        id: "parent:guardian_2",
        required: false,
        control: <Form.Control type={"text"} />
    },
    {
        label: "Email Address (Guardian #1)",
        id: "parent:guardian_1_email",
        required: false,
        control: <Form.Control type={"email"} />
    },
    {
        label: "Email Address (Guardian #2)",
        id: "parent:guardian_2_email",
        required: false,
        control: <Form.Control type={"email"} />
    },
    {
        label: "Employer (Guardian #1)",
        id: "parent:guardian_1_employer",
        required: false,
        control: <Form.Control type={"text"} />
    },
    {
        label: "Employer (Guardian #2)",
        id: "parent:guardian_2_employer",
        required: false,
        control: <Form.Control type={"text"} />
    },
    {
        label: "Are you a U.S. Citizen?",
        id: "parent:citizen",
        required: true,
        control: <Form.Control as="select">
            <option disabled selected> -- select an option -- </option>
            <option>Yes</option>
            <option>No</option>
        </Form.Control>,
    },
    {
        label: "Permanent Resident?",
        id: "parent:resident",
        required: false,
        control: <Form.Control as="select">
            <option disabled selected> -- select an option -- </option>
            <option>Yes</option>
            <option>No</option>
        </Form.Control>,
    },
    {
        label: "First Language",
        id: "parent:first_language",
        required: true,
        control: <Form.Control type={"text"} />
    },
    {
        label: "How many family members are there in your home?",
        id: "parent:num_family_members",
        required: true,
        control: <Form.Control type={"number"} />
    },
    {
        label: "Ages",
        id: "parent:ages",
        required: true,
        control: <Form.Control type={"text"} />
    },
    {
        label: "In order for us to provide an optimal environment for your child's success, please let us know of any special situations impacting their educational performance (student health issues, learning issues, illness in the family, loss of employment, deceased or absent parent, etc.)",
        required: false,
        id: "parent:special",
        control: <Form.Control as={"textarea"} rows={3} />
    },
    {
        label: "What words would you use to describe your child?",
        required: true,
        id: "parent:words",
        control: <Form.Control type={"text"} />,
    }
]

export default parentForm;
import React from 'react';
import {Button} from "react-bootstrap";
import GeneralInformation from "./GeneralInformation";
import Tuition from "./Tuition";

interface StartPageProps {
    startForm: any;
}

const StartPage = (props: StartPageProps) => {
    return <div>
        <GeneralInformation />
        <Tuition />
        <Button variant={'outline-primary'}
                onClick={() => props.startForm(true)}
                className={'section-button'}
        >
           <b>Apply</b>
        </Button>
    </div>
}

export default StartPage;
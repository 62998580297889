import React, { useState } from 'react';
import {Button} from "react-bootstrap";
import EnrollmentFormMaster from "./EnrollmentFormMaster";
import StartPage from "./StartPage";
import EndPage from "./EndPage";


const EnrollmentForm = (props: any) => {
    const [formStarted, startForm] = useState(false);
    const [endFormData, endForm] = useState({
        formEnded: false,
        successful: false,
    });
    if (formStarted) {
        if (endFormData.formEnded) {
            return <EndPage endData={endFormData}/>
        } else {
            return <EnrollmentFormMaster endForm={endForm} />
        }
    } else {
        return <StartPage startForm={startForm} />
    }
};

export default EnrollmentForm;
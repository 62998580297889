import React from 'react';
import 'bootswatch/dist/sandstone/bootstrap.css';
import './App.css';
import BasePage from "./BasePage";
import EnrollmentForm from "./EnrollmentForm";
import firebase from "firebase";

// Firebase Config
const FIREBASE_CONFIG = {
    apiKey: "AIzaSyBf8NIZe43pJJV6cY9DD41glwozU831Wbg",
    authDomain: "quest-enrollment.firebaseapp.com",
    projectId: "quest-enrollment",
    storageBucket: "quest-enrollment.appspot.com",
    messagingSenderId: "104485412784",
    appId: "1:104485412784:web:f9dfcdcd542c325556c075",
    measurementId: "G-HQBJBPLP06"
};


function App() {
    firebase.initializeApp(FIREBASE_CONFIG);
    return (
    <BasePage>
       <EnrollmentForm />
    </BasePage>
  );
}

export default App;

import React from 'react';
import { Jumbotron } from "react-bootstrap";

const GeneralInformation = () => {
    return <Jumbotron>
        <h4>Thank you for your interest in the Quest College Prep Program</h4>
        <p>Quest is a not-for-profit program assisting Collier County students to and through college.
        If your child is selected to be in Quest, he/she will receive the following:</p>
        <ul>
           <li>
               Preparation in the skills needed for high school and college
               <ul>
                   <li>
                       Time Management
                   </li>
                   <li>
                       Organizational Skills
                   </li>
                   <li>
                       Study Skills
                   </li>
               </ul>
           </li>
            <li>
                Personalized counseling and assistance with high school course selection
            </li>
            <li>
                SAT/ACT Test preparation
            </li>
            <li>
                Essay writing workshops for college and scholarship applications
            </li>
            <li>
                Career research and counseling
            </li>
            <li>
                Scholarship research and assistance in completing the applications
            </li>
            <li>
                College financial aid workshop
            </li>
        </ul>
    </Jumbotron>
}

export default GeneralInformation;
import React, { useState } from 'react';
import {Col, Form, Row} from "react-bootstrap";
import FormField from "./FormField";


interface FormSectionProps {
    name: string;
    description: string;
    form: FormField[];
    formData: any;
    setForm: any;
}

const FormSection = (props: FormSectionProps) => {
    let formComponents = []
    for (let formFieldIdx in props.form) {
        let formField: FormField = props.form[formFieldIdx];

        let fieldProps: any = {
            onChange: (e: any) => {
                if (props.formData === undefined) {
                    props.formData = {};
                }
                let value;
                if (e.target.type === "select-multiple") {
                    value = [];
                    for (let o of e.target.options) {
                        if (o.selected) {
                            value.push(o.value || o.text);
                        }
                    }
                    value = value.join(', ')
                } else {
                    value = e.target.value;
                }
                props.formData[formField.id] = value;
                props.setForm(props.formData);
            }
        };

        /*
        if (props.formData && props.formData[formField.id]) {
            fieldProps.value = props.formData[formField.id];
        }
         */
        formComponents.push(
            <Form.Group controlId={formField.id} key={formFieldIdx}>
                <Form.Label>{formField.label}{formField.required ? <i className={'a-required'}>*</i>: ''}</Form.Label>
                {React.cloneElement(formField.control, fieldProps)}
            </Form.Group>
        )
    }
    return <Row>
        <Col>
            <Row>
                <h3>{props.name}</h3>
            </Row>
            <Row>
                <p>{props.description}</p>
            </Row>
            <Row>
                <Form>
                    {formComponents}
                </Form>
            </Row>
        </Col>
    </Row>
};

export default FormSection;
import React from 'react';
import { Form } from 'react-bootstrap';
import FormField from "./FormField";

const studentForm: FormField[] = [
    {
        label: "Student name",
        id: "student:name",
        required: true,
        control: <Form.Control type={"text"} />
    },
    {
        label: "Nickname",
        id: "student:nickname",
        required: false,
        control: <Form.Control type={"text"} />
    },
    {
        label: "Cell #",
        id: "student:cell",
        required: true,
        control: <Form.Control type={"phone"} />
    },
    {
      label: "Email",
      id: "student:email",
      required: true,
      control: <Form.Control type={"email"} />
    },
    {
        label: "Race/Ethnicity",
        id: "student:race_ethnicity",
        required: true,
        control: <Form.Control as="select">
            <option disabled selected> -- select an option -- </option>
            <option>White/Non-Hispanic</option>
            <option>Black/Non-Hispanic</option>
            <option>Hispanic</option>
            <option>Asian/Pacific Islander</option>
            <option>Native American</option>
            <option>Other</option>
        </Form.Control>,
    },
    {
        label: "If you selected other, please specify",
        id: "student:race_ethnicity_other",
        required: false,
        control: <Form.Control type={"text"} />
    },
    {
        label: "Birth Date (mm/dd/yyyy)",
        control: <Form.Control type={"text"} placeholder={"mm/dd/yyyy"}/>,
        required: true,
        id: "student:birth_date"
    },
    {
        label: "Current Age",
        control: <Form.Control type={"number"} />,
        required: true,
        id: "student:current_age",
    },
    {
        label: "Gender",
        control: <Form.Control type={"text"} placeholder={"M/F"} />,
        required: true,
        id: "student:gender",
        validation: (s: any) => {
            return (s.length === 1);
        }
    },
    {
        label: "Grade",
        control:  <Form.Control type={"number"} />,
        required: true,
        id: "student:grade"
    },
    {
        label: "School",
        control: <Form.Control type={"text"} />,
        required: true,
        id: "student:school"
    },
    {
        label: "What do your parents do for a living?",
        control:  <Form.Control type={"text"} />,
        required: true,
        id: "student:parents_living"
    },
    {
        label: "Who do they work for?",
        control: <Form.Control type={"text"} />,
        required: true,
        id: "student:parent_employer"
    },
    {
        label: "Do you have brothers or sisters?",
        control: <Form.Control as={"select"}>
            <option disabled selected> -- select an option -- </option>
            <option>Yes</option>
            <option>No</option>
        </Form.Control>,
        id: "student:siblings",
        required: true
    },
    {
        label: "If so, how many?",
        control:  <Form.Control type={"number"} />,
        required: false,
        id: "student:siblings_num"
    },
    {
        label: "Have either of your parents completed college? If so, where?",
        control: <Form.Control type={"text"} />,
        required: true,
        id: "student:parents_college"
    },
    {
        label: "What is your favorite subject?",
        control: <Form.Control type={"text"} />,
        required: true,
        id: "student:favorite_subject"
    },
    {
        label: "What is your least favorite subject? Why?",
        control: <Form.Control type={"text"} />,
        required: true,
        id: "student:least_favorite_subject"
    },
    {
        label: "Have you done poorly in any class or subject? If so, which one? Why? What did you do to improve your grade?",
        control: <Form.Control as={"textarea"} rows={3} />,
        required: true,
        id: "student:done_poorly",
    },
    {
        label: "Are you willing to take classes that challenge you academically?",
        control: <Form.Control type={"text"} />,
        required: true,
        id: "student:challenging_classes"
    },
    {
        label: "Have you ever worked with a tutor? If so, what subject(s)?",
        control: <Form.Control type={"text"} />,
        required: true,
        id: "student:tutor"
    },
    {
        label: "How do you spend your free time?",
        control: <Form.Control as={"textarea"} rows={2} />,
        required: true,
        id: "student:free_time",
    },
    {
        label: "Have you thought about what career you'd like to pursue? If so, what would it be?",
        required: true,
        id: "student:career",
        control: <Form.Control as={"textarea"} rows={3} />,
    },
    {
        label: "In what activities, clubs, or sports do you participate in outside of school?",
        required: true,
        id: "student:activities",
        control: <Form.Control as={"textarea"} rows={3} />,
    },
    {
        label: "What are your talents/interests?",
        required: true,
        id: "student:talents",
        control: <Form.Control as={"textarea"} rows={3} />,
    },
    {
        label: "Why do you want to go to college?",
        required: true,
        id: "student:why_college",
        control: <Form.Control as={"textarea"} rows={3} />,
    },
    {
        label: "It takes a lot of hard work to be ready to go to college. Are you willing to make the time commitment to Quest and your future?",
        required: true,
        id: "student:commitment",
        control: <Form.Control as={"textarea"} rows={3} />,
    },
];

export default studentForm;
import React from 'react';
import {Jumbotron, Table} from "react-bootstrap";

const Tuition = (props: any) => {
    return <Jumbotron>
        The tuition to participate in the Quest College Prep Program is as follows:
        <Table>
            <tbody>
            <tr>
                <td>
                    Freshmen Year (9th Grade)
                </td>
                <td>
                    $1,100 / student
                </td>
            </tr>
            <tr>
                <td>
                    Sophomore Year (10th Grade)
                </td>
                <td>
                    $1,395 / student
                </td>
            </tr>
            <tr>
                <td>
                    Junior / Senior Year
                </td>
                <td>
                    $3,450 / student*
                </td>
            </tr>
            </tbody>
        </Table>
        <p>
            * The fee of $3,450 covers high school juniors through their senior year -- the entire college application process.
            If you are interested in applying, the application and supporting documents must be completed and returned to Quest.
            Qualified applications will be asked to attend a student and parent interview at the Quest Center.
        </p>
        <p>
            <b>
                If you have any questions about the Quest College Prep Program or application process, please email us at <a target="_blank" href={'mailto:questcollegeprep@gmail.com'}>questcollegeprep@gmail.com</a>
            </b>
        </p>
    </Jumbotron>
}

export default Tuition;
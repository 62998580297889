import React from 'react';
import { Container, Navbar, Image, Row, Col } from 'react-bootstrap';
import QuestLogo from './images/questlogo.png';
import firebase from "firebase";

const startAnalytics = () => {
    return new Promise(() => {
        const analytics = firebase.analytics();
        analytics.logEvent("visit")
    })
}

const BasePage = (props: any) => {
    startAnalytics();
    return <Container fluid>
        <Navbar bg="light" expand={"lg"}>
            <Navbar.Brand>
                <Image src={QuestLogo} width={100} alt={'Quest for Success Logo'} />
            </Navbar.Brand>
            <Navbar.Collapse className={'navbar-right'}>
                <Navbar.Text>
                    <h3>
                        Application Forms
                    </h3>
                </Navbar.Text>
            </Navbar.Collapse>
        </Navbar>
        <Container>
            <br />
            <Row>
                <Col>
                    {props.children}
                </Col>
            </Row>
        </Container>
    </Container>
}

export default BasePage;
import React, {useState} from 'react';
import {Alert, Button, Col, Jumbotron, Nav, ProgressBar, Row, Tab} from "react-bootstrap";
import FormSection from "./FormSection";
import parentForm from "./ParentForm";
import studentForm from "./StudentForm";
import FormField from "./FormField";
import SubmitForm from "./Submit";
import EndFormData from "./EndFormData";
import firebase from "firebase";

const checkmark = "✔️";
const empty = "☐";

interface TabProps {
    done: boolean;
    name: string;
    eventkey: string;
}

const TabWithCheck = (props: any) => {
    return <Nav.Item>
        <Nav.Link eventKey={props.eventkey}>{props.done ? checkmark : empty} {props.name}</Nav.Link>
    </Nav.Item>
}

interface SectionDoneInfo {
    done: boolean;
    errorMessages: string[];
}

const checkSectionDone = (formData: any, sectionFields: FormField[]): SectionDoneInfo => {
    if (formData === undefined) {
        return {
            done: false,
            errorMessages: []
        };
    } else {
        let done = true;
        let errorMessages: string[] = [];

        for (let field of sectionFields) {
            if (field.required) {
                if (formData[field.id]) {
                    if (field.validation) {
                        if (!field.validation(formData[field.id])) {
                            done = false;
                            errorMessages.push(`Invalid input for field ${field.id.split(':')[1].replaceAll('_', ' ')} on ${field.id.split(':')[0]} section`)
                        }
                    }
                } else {
                    done = false;
                }
            }
        }

        return {
            done: done,
            errorMessages: errorMessages,
        }
    }
}

const formatErrorMessages = (errorMessages: string[]): any => {
    return errorMessages.map((e, o) =>
        <Row>
            <Col>
                <Alert key={o} variant={'danger'}>
                    {e}
                </Alert>
            </Col>
        </Row>
    )
}

interface EnrollmentFormProps {
    endForm: any;
}

const EnrollmentFormMaster = (props: EnrollmentFormProps) => {

    const [formData, setFormData] = useState({});

    let [parentSectionDoneInfo, setParentSectionDoneInfo] = useState(checkSectionDone(formData, parentForm));
    let [studentSectionDoneInfo, setStudentSectionDoneInfo] = useState(checkSectionDone(formData, studentForm));


    return <div>
        <Row>
            <Col>
                <Jumbotron>
                    All fields marked with a <i className={'a-required'}>*</i> must be completed. For each section, both
                    the parent and the student application, there is either an empty box ({empty}), or a checkmark ({checkmark})
                    next to the name. When all required<i className={'a-required'}>*</i>  fields have been completed,
                    the section will have a checkmark {checkmark}, and you will be able to submit the form
                </Jumbotron>
            </Col>
        </Row>
        <Tab.Container id="left-tabs-example" defaultActiveKey="parents">
            <Row>
                <Col md={3}>
                    <Nav variant="pills" className="flex-column">
                       <TabWithCheck name={"Parent Application"} done={parentSectionDoneInfo.done} eventkey={"parents"}/>
                       <TabWithCheck name={"Student Application"} done={studentSectionDoneInfo.done} eventkey={"students"} />
                    </Nav>
                </Col>
                <Col md={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="parents">
                            {parentSectionDoneInfo.errorMessages ?
                                formatErrorMessages(parentSectionDoneInfo.errorMessages): ''}
                            <FormSection description={"One or both parents should fill this section out"}
                                         name={"Parent Application"}
                                         form={parentForm}
                                         formData={formData}
                                         setForm={(f: any) => {
                                            setFormData(f);
                                            setParentSectionDoneInfo(checkSectionDone(formData, parentForm))
                                         }}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="students">
                            {studentSectionDoneInfo.errorMessages ?
                                formatErrorMessages(studentSectionDoneInfo.errorMessages): ''}
                            <FormSection description={"The student should fill this section out"}
                                         name={"Student Application"}
                                         form={studentForm}
                                         formData={formData}
                                         setForm={(f: any) => {
                                             setFormData(f);
                                             setStudentSectionDoneInfo(checkSectionDone(formData, studentForm))
                                         }}
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
        <Row>
            <Button variant={'outline-primary'}
                    disabled={!(parentSectionDoneInfo.done && studentSectionDoneInfo.done)}
                    className={'section-button'}
                    onClick={() => {
                        if (parentSectionDoneInfo.done && studentSectionDoneInfo.done) {
                            let endInfo: EndFormData = {
                                formEnded: true,
                                successful: true,
                            }
                            const analytics = firebase.analytics();
                            SubmitForm(formData).then((appId) => {
                                analytics.logEvent("success")
                                endInfo.appId = appId;
                            }).catch((err) => {
                                analytics.logEvent("error")
                                endInfo.successful = false;
                            }).finally(() => props.endForm(endInfo));
                        }
                    }}
            >
                Submit application
            </Button>
        </Row>
    </div>
}

export default EnrollmentFormMaster;
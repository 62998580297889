import React from 'react';
import {Col, Jumbotron, Row} from "react-bootstrap";
import EndFormData from "./EndFormData";

interface EndPageData {
    endData: EndFormData;
}

const EndPage = (props: EndPageData) => {
    let message;
    if (props.endData.successful && props.endData.appId) {
        message = <div>
            <p>Thank you for applying to Quest</p>
            <p>Your application ID is <b>{props.endData.appId}</b></p>
        </div>
    } else {
        message = <div>
            There was an unexpected error trying to submit your application.
            Please try again, and if the error persists, contact Quest at <a target='_blank'
                                                                             href={'mailto:questcollegeprep@gmail.com'}>questcollegeprep@gmail.com</a>
        </div>
    }
    return <Row>
        <Col>
            <Jumbotron>
                {message}
            </Jumbotron>
        </Col>
    </Row>
}

export default EndPage;
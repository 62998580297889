import React from 'react';
import firebase from "firebase";

const SubmitForm = (formData: any): Promise<any> => {
    formData.time = new Date().toISOString();
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("enrollments").add(formData)
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
                resolve(docRef.id);
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
                reject(error);
            });
    });
}

export default SubmitForm;